<template>
 <v-container style="margin-top: 50px">
    <div class="text-h4" style="margin-bottom: 50px">Our virtual pub experience is built on top of <a href="www.ohyay.co">Ohyay</a>. Here's how to use it!</div>
    <div class="text-h2 font-weight-bold title">How to join the game</div>
    <div class="text-h5 body">
      <div class="paragraph">When you follow the link provided by Trivia Night, you'll arrive at a sign-in page for your specific trivia game.</div>
      <div class="paragraph">If you're participating in a private game, it might have a password protecting it. Enter it here:</div>

      <div class="d-flex justify-center image">
        <v-img :max-width="maxWidth()" :src="require('../assets/ohyay/password.png')"></v-img>
      </div>

      <div class="paragraph">Once you've entered the password (or if your game has no password), you might see a permissions pop-up from your browser:</div>


      <div class="d-flex justify-center image">
        <v-img :max-width="maxWidth()" :src="require('../assets/ohyay/permissions.png')"></v-img>
      </div>

      <div class="paragraph">You should <span class="font-weight-bold">allow</span> both of those permissions. Don't worry, you'll be able to mute yourself or turn off your camera later!</div>

      <div class="paragraph">Finally, you can sign into the game. Enter the name you want everyone else to see (your first name, not your team's name!), and select the camera, microphone, and speakers you want to use. The defaults are likely fine!</div>

      <div class="d-flex justify-center image">
        <v-img :max-width="maxWidth()" :src="require('../assets/ohyay/signin.png')"></v-img>
      </div>

      <v-alert dense text outlined type="warning" color="accent" style="margin-top: 20px" class="text-h5">We recommend muting yourself to start by clicking the microphone icon until it has a red slash over it (as in the image above).</v-alert>
    </div>

    <div class="text-h2 font-weight-bold title">How to join a room</div>

    <div class="text-h5 body">
      <div class="paragraph">On the left side of your screen, you'll see the "Room Map":</div>

      <div class="d-flex justify-center image">
        <v-img :max-width="maxWidth()" :src="require('../assets/ohyay/roommap.png')"></v-img>
      </div>

      <v-alert dense text outlined type="warning" color="accent" style="margin-top: 20px" class="text-h5">If you ever can't see the room map, click on the icon that looks like this in the upper left corner:

        <div class="d-flex justify-center" style="margin: 10px 0">
          <v-img :max-width="25" :src="require('../assets/ohyay/mapicon.png')"></v-img>
        </div>
      </v-alert>



      <div class="paragraph">If you click on any of the room names, you'll be immediately taken into that room. Once inside, you'll only be able to hear others in the same room, as well as the host (but the host won't be able to hear you!).</div>

      <div class="paragraph">From your team's room, you'll be able to hear the host, see the questions, confer with your teammates, and submit your answers. No need to juggle any additional windows or video calls!</div>
    </div>


   <div class="text-h2 font-weight-bold title">How to find your team</div>

   <div class="text-h5 body">
     <div class="paragraph">Once you've joined the game, you'll be in the main "room". If you aren't muted, the host and anyone else in that room will be able to hear you. Feel free to say hi or ask for help!</div>

     <div class="paragraph">The ten "Team Rooms" are all identical, but aren't assigned to specific teams. Take a quick scan over the list, and if you see your teammates, join them in that room. If you don't see them, that means you're the first one to arrive. Pick any empty room and wait for them to join you.</div>
   </div>

   <div class="text-h2 font-weight-bold title">How to communicate</div>

   <div class="text-h5 body">
     <div class="d-flex justify-center" style="margin: 10px 0">
       <v-img :max-width="maxWidth()" :src="require('../assets/ohyay/guide.png')"></v-img>
     </div>

     <div class="paragraph">While you're going to spend most of your time in your team's room, there are a few ways to communicate with other teams:</div>

     <div class="text-h4" style="margin-top: 20px">1. Join the main room</div>
     <div class="paragraph">
      Since it's so easy to jump between rooms, feel free to come hang out in the main room between rounds, or if you want to ask the host a quick question during the round and don't want to type. Only people in the main room will be able to hear you, so don't be shy!
     </div>

     <div class="text-h4" style="margin-top: 20px">2. Use the chat</div>
     <div class="paragraph">
      We recommend enabling the chat window, which will live underneath your room map. You can do this by clicking on this icon:
     </div>

     <div class="d-flex justify-center" style="margin: 10px 0">
       <v-img :max-width="25" :src="require('../assets/ohyay/chaticon.png')"></v-img>
     </div>

     <div class="paragraph">You can use the chat to send messages to specific individuals, your team, or everyone.</div>


     <div class="text-h4" style="margin-top: 20px">3. Use reactions</div>
     <div class="paragraph">
       One of the most fun features of Ohyay is reactions, which can be used at the bottom of your screen. These are emojis or small snippets of text that can be seen by everyone (in all rooms), but disappear quickly. They're great for reacting to a hard question or bad pun, and the options are limitless. You can even connect your <a href="https://www.bitmoji.com/">Bitmoji</a> account to allow for personalized reaction pictures!
     </div>

   </div>

   <div class="text-h2 font-weight-bold title">Tech limitations</div>

   <div class="text-h5 body">
     <div class="paragraph">While Ohyay should work for just about everyone, it won't work as well on mobile, so we recommend using a computer or tablet. This is particularly important if you'll be the one submitting answers for your team.</div>

     <v-alert dense text outlined type="warning" color="accent" style="margin-top: 20px" class="text-h5">If you're on an iPad or iPhone, Ohyay will <span class="font-weight-bold">only</span> work with Safari! Chrome or other browsers won't be allowed to join the game.</v-alert>
   </div>
 </v-container>
</template>

<script>
export default {
  name: "OhYay",
  metaInfo: {
    title: 'Ohyay instructions'
  },
  methods: {
    maxWidth() {
      return this.$isMobile ? "275" : "600";
    },
  }
}
</script>

<style scoped>
.body {
  padding-top: 20px;
}
.paragraph {
  padding: 5px;
}
.image {
  margin: 40px 20px;
}
.title {
  margin-top: 40px;
}
</style>